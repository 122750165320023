import 'what-input'
import 'tslib'
import { gsap, TweenMax } from 'gsap/all'
// import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

gsap.registerPlugin(TweenMax)
class Common {
  JsPageTop: HTMLElement | null
  constructor() {
    this.JsPageTop = document.querySelector('.js-page-top')
    this.addEvent()
  }

  static pagesTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  addEvent() {
    this.JsPageTop?.addEventListener(
      'click' || 'touchstart',
      (e) => {
        e.preventDefault()
        Common.pagesTop()
      },
      false
    )
  }
}

new Common()

class Menu {
  BottomOpen: HTMLElement | null
  BottomClose: HTMLElement | null
  BottomCloseSP: HTMLElement | null
  MenuElement: HTMLElement | null
  MenuElementWrap: HTMLElement
  MenuElementBg: HTMLElement
  HeaderMenuElement: HTMLElement | null
  HeaderElement: HTMLElement | null

  constructor() {
    this.BottomOpen = document.querySelector<HTMLElement>('[data-open-botton]')
    this.BottomClose = document.querySelector<HTMLElement>(
      '[data-close-botton]'
    )
    this.BottomCloseSP = document.querySelector<HTMLElement>(
      '[data-close-botton-sp]'
    )
    this.MenuElement = document.querySelector<HTMLElement>('[data-menu]')
    this.HeaderElement = document.querySelector<HTMLElement>('[data-header]')
    this.HeaderMenuElement = document.querySelector<HTMLElement>(
      '[data-header-menu]'
    )
    this.MenuElementWrap = document.querySelector(
      '[data-menu-wrap]'
    ) as HTMLElement
    this.MenuElementBg = document.querySelector('[data-menu-bg]') as HTMLElement

    this.addEvent()
    // this.viewFoucs()
  }

  viewFoucs() {
    const hheader = this.HeaderElement?.clientHeight as number

    TweenMax.set(this.MenuElementWrap, {
      height: `${window.innerHeight - (hheader + 50)}px`,
    })
  }

  HeaderMenuOpen() {
    this.MenuElement?.setAttribute('aria-hidden', 'false')
    this.BottomOpen?.setAttribute('disabled', 'disabled')

    TweenMax.to(this.MenuElementWrap, 0.5, {
      display: 'block',
      opacity: 1,
      onComplete: () => {
        if (window.innerWidth <= 750) {
          this.viewFoucs()
          this.BottomCloseSP?.removeAttribute('disabled')
          TweenMax.set(this.BottomCloseSP, {
            display: 'block',
          })
          TweenMax.set(this.BottomOpen, {
            display: 'none',
          })
          return
        }
        this.BottomClose?.removeAttribute('disabled')
      },
    })

    // disableBodyScroll(this.MenuElementWrap)
  }

  HeaderMenuClose() {
    this.MenuElement?.setAttribute('aria-hidden', 'true')
    this.BottomClose?.setAttribute('disabled', 'disabled')
    this.BottomCloseSP?.setAttribute('disabled', 'disabled')
    this.BottomOpen?.removeAttribute('disabled')

    TweenMax.to(this.MenuElementWrap, 0.5, {
      display: 'none',
      opacity: 0,
      onComplete: () => {
        this.BottomOpen?.removeAttribute('disabled')
        if (window.innerWidth <= 750) {
          TweenMax.set(this.BottomCloseSP, {
            display: 'none',
          })
          TweenMax.set(this.BottomOpen, {
            display: 'block',
          })
          return
        }
      },
    })

    // enableBodyScroll(this.MenuElementWrap)
  }

  addEvent() {
    this.BottomOpen?.addEventListener(
      'click',
      () => {
        this.HeaderMenuOpen()

        TweenMax.to('.c-header_menu-bg', 0.3, {
          display: 'block',
          opacity: 0.95,
          onComplete: () => {
            this.MenuElementBg?.classList.add('open')

            TweenMax.to(this.MenuElement, 0.3, {
              display: 'block',
              opacity: 1,
              onComplete: () => {
                this.MenuElement?.classList.add('open')
              },
            })
          },
        })
      },
      false
    )

    this.BottomClose?.addEventListener(
      'click',
      () => {
        this.HeaderMenuClose()

        TweenMax.to(this.MenuElement, 0.5, {
          display: 'none',
          opacity: 0,
          onComplete: () => {
            this.MenuElement?.classList.remove('open')

            TweenMax.to('.c-header_menu-bg', 0.3, {
              display: 'none',
              opacity: 0,
              onComplete: () => {
                this.MenuElementBg?.classList.remove('open')
              },
            })
          },
        })
      },
      false
    )

    this.BottomCloseSP?.addEventListener(
      'click',
      () => {
        this.HeaderMenuClose()

        TweenMax.to(this.MenuElement, 0.5, {
          display: 'none',
          opacity: 0,
          onComplete: () => {
            this.MenuElement?.classList.remove('open')

            TweenMax.to('.c-header_menu-bg', 0.3, {
              display: 'none',
              opacity: 0,
              onComplete: () => {
                this.MenuElementBg?.classList.remove('open')
              },
            })
          },
        })
      },
      false
    )
  }
}

new Menu()
